module.exports = {
	lang: {
		LanguageTransform: '中英文切换',
		vbs: '推流地址最多可以添加四个',
		helloworld: '你好世界',
		forgetpassword: '忘记密码',
		equipment: '设备',
		media: '媒体',
		Workorder: '工单',
		journal: '日志',
		equipmentName: '设备名称',/* 设备板块 */
		hardwareVersion: '硬件版本',
		softwareVersion: '软件版本',
		startRecording: '开始录制',
		stopRecording: '结束录制',
		transmissionRate: '传输码率',
		codeRate: '编码码率',
		inputSystem: '输入制式',
		electricitySurplus: '电量剩余',
		diskSpace: '磁盘空间',
		bitRate: '码率',
		delayed: '缓冲',
		streamingAddress: '推流地址',
		Pullflowaddress: '拉流地址',
		importScheme: '导入方案',
		showPushStreamName: '显示推流名称',
		codingAlgorithm: '编码算法',
		transportProtocol: '传输协议',
		transmissionStrategy: '输出策略',
		bitstreamMode: '码流模式',
		delayTime: '延时时间',
		delaySets: '延时设置',
		bufferTime: '缓冲时间',
		resolvingPowert: '分辨率',
		cancel: '取消',
		submit: '提交',
		basicInformation: '基础信息',/* 设备信息 */
		model: '型号',
		workingMode: '工作模式',
		workingHours: '工作时间',
		realTimeBitRate: '实时码率',
		rateControl: '码率控制',
		workingCondition: '工作状态',
		onLine: '在线',
		outLine: '离线',
		istaocan: '是否开启套餐',
		individual: '个',
		available: '可用',
		common: '共',
		time: '时间',
		type: '类型',
		selectDate: '选择日期',
		search: '搜索',
		detailedInformation: '详细信息',
		nameModification: '修改名称',
		copyAddress: '复制地址',
		copyAddressa:'复制地址',
		fileName: '设备类型',
		creationTime: '创建时间',
		programDuration: '节目时长',
		sourceEquipment: '来源设备',
		fileSize: '设备编号',
		yunweiad: '运维管理',
		devdownload:'下载地址',
		return: '返回',
		submitWorkOrder: '提交工单',/* 工单 */
		workOrderStatus: '工单状态',
		to: '至',
		pleaseSelect: '全部',
		workOrderNo: '工单编号',
		NameModification: '问题描述',
		problemDescription: '问题类型',
		state: '状态',
		submissionTime: '提交时间',
		details: '详情',
		closeWorkOrder: '关闭工单',
		operation: '操作',
		pleaseEnterAFileName: '请输入文件名',
		electWorkOrder: '选择工单',
		writeWorkOrder: "填写工单",
		userRegistrationClass: '用户注册类',
		liveBroadcastTerminalClass: '直播终端类',
		streamingMediaPushClass: '流媒体推送类',
		/*copyAddress: '复制地址',
		copyAddress: '复制地址',
		NameModification: '修改名称',
		copyAddress: '复制地址',
		copyAddress: '复制地址',
		NameModification: '修改名称',
		NameModification: '修改名称',
		copyAddress: '复制地址',
		copyAddress: '复制地址', */


		AddServer: '添加服务器',
		ServerSerial: '服务器序列号',
		Please: '请选择',
		Cloudserver: '云服务器',
		Localserver: '本地服务器',
		ServerDetails: '服务器详情',
		EssentialInformation: '基本信息',
		Deleted: '禁用',
		normal: '正常',
		nonormal: '锁定',
		fault: '故障',
		maintain: '维护',
		offline: '离线',
		lease: '租赁',
		sale: '销售',
		nucleus: '核',
		ServerName: '服务器名称',
		ServerType: '服务器类型',
		Location: '所在区域',
		ServerNetwork: '服务器网络配置',
		RunningState: '运行状态',
		OperateState: '运营状态',
		BusinessState: '业务进程状态',
		LeaseTime: '租赁起止时间',
		OnlineTerminal: '在线终端数',
		ConfigureInformation: '配置信息',
		IpAddress: 'IP地址',
		VisitAddress: '访问IP',
		port: '端口',
		ResourceStatus: '资源状态',
		CpuNumber: 'CPU核数',
		Cpuutilization: 'CPU使用率',
		broadband: '带宽',
		Occupiedbroadband: '当前流量',
		shangxiang: '上行流量',
		xiaxing: '下行流量',
		neicunad:"内存(余量)",
		neicunac:"内存(总量)",
		chipanac:"磁盘(剩余)",
		chipanad:"磁盘(总量)",
		Occupieddisk: '已用磁盘',
		Occupiedmemory: '已用内存',
		disk: '磁盘',
		Memory: '内存',
		preservation: '保存',
		Pleaseinputtheinformation: '请把信息输入完整',
		Theserialnumbercannot: '序列号里不能包含文字或者特殊字符',
		Pleaseinputthecorrect: 'cpu请输入整数',
		Pleaseenterthecorrectsize: '宽带请输入整数',
		Pleaseenterthecorrectdisk: '磁盘请输入整数',
		Pleaseenterthecorrectmemory: '内存请输入整数',

		servernameorsn: '请输入服务器名称或SN',
		equipmentnameorsn: '请输入设备名或sn号',
		whole: '全部',
		Generalalarm: '一般告警',
		majoralarm: '重要告警',
		Emergencyalarm: '紧急告警',
		server: '服务器',
		networkconfiguration: '网络配置',
		abnormal: '异常',
		Leasetime: '租赁时间',
		edit: '编辑',
		previouspage: '上一页',
		nextpage: '下一页',
		EditServer: '编辑服务器',
		PleaseIPaddress: '请输入IP地址',
		Pleaseenterthecorrect: '请输入正确的IP地址',
		Pleaseentertheaccess: '请输入访问地址',
		Pleaseenterthecorrectaccess: '请输入正确的访问地址',
		Pleaseentertheport: '请输入端口号',
		Pleaseenterthecorrectport: '请输入正确的端口号',
		Pleaseentertheserver: '请输入服务器名称',
		Pleaseentertheregion: '请输入所在区域',
		Pleaseselectrunning: '请选择运行状态',
		Pleaseselecttype: '请选择类型',
		Pleaseselectoperation: '请选择运营状态',

		PleaseenterthedevicenameorSn: '请输入设备名称或SN',
		Portalserver: '入口服务器',
		logintime: '登录时间',
		Areyousureyouwanttodeletethisserver: '确定要删除该服务器吗',
		Deleteserver: '删除服务器',

		Addcontract: '添加合约',
		Pleaseinputkeywords: '请输入关键字',
		Nameoflessee: '租赁方名称',
		Targetaccountnumber: '目标账号',
		Numberofterminals: '设备数量',
		Terminalserialnumber: '设备序列号',
		Completed: '已完成',
		Underimplementation: '执行中',
		contacts: '联系人',
		Contactnumber: '联系人电话',
		contactnumbers: '联系电话',
		fixedtelephone: '固定电话',
		address: '地址',
		Purchaseinformation: '购买信息',
		Contractname: '合约名称',
		Buyertype: '买方类型',
		naturalperson: '自然人',
		EnterpriseV: '企业',
		Startdate: '开始日期',
		Enddate: '结束日期',
		Selectdevice: '选择设备',
		Selectserver: '选择服务器',
		Adddevice: '添加设备',
		Customerinformation: '客户信息',
		Serverlist: '服务器列表',
		serialnumber: '序列号',
		confirm: '确认',
		Pleaseentercontactphone: '请输入联系人电话',
		Pleaseenterthecorrectcontactphone: '请输入正确的联系人电话',
		Pleaseenterthecontractname: '请输入合约名称',
		Pleaseselectthetypeofrentdebit: '请选择租借方类型',
		Pleaseenterleaseholder: '请输入租赁方名称',
		Pleaseenterthetarget: '请输入目标账号',
		Pleaseenterthecorrectemail: '请输入正确的邮箱地址或手机号',
		Pleaseselecttheleasetime: '请选择租赁时间',
		Pleaseentercontact: '请输入联系人',
		Addleasecontract: '添加租赁合约',
		Editcontract: '编辑合约',
		Contractdetails: '合约详情',
		Pleaseselectthedevicefirst: '请先选择设备',
		Pleaseselecttheserverfirst: '请先选择服务器',
		Pleaseselectadifferentdevice: '请选择一个不同的设备',
		Pleaseselectadifferentserver: '请选择一个不同的服务器',
		Pleaseselectatleastonedevice: '请添加一个服务器',
		Pleaseselectatleastonedevices: '请添加一个设备',


		Buyersname: '买方名称',
		Pleaseenterthebuyersname: '请输入买方名称',
		Addsalescontract: '添加销售合约',

		servicetime: '服务时间',
		Trafficquota: '流量配额 (MB)',
		storagequota: '存储配额 (MB)',
		Pleaseenterstoragequota: '请输入存储配额',
		Pleaseenterapositiveinteger: '请输入包含-1以上整数',
		Pleaseinputtrafficquota: '请输入流量配额',
		Addservicecontract: '添加服务合约',
		mages: "-1则表示配额不限制",

		Pleaseselectthesourcedevice: '请选择来源设备',
		Pleaseenterthefilenameoruser: '文件名/用户邮箱',
		User: '所属用户',
		download: '下载',
		deletes: '删除',
		Areyousureyouwanttodelete: '确定要删除该文件吗',
		determine: '确定',
		Editfile: '编辑文件',
		Deletefile: '删除文件',

		Alarmsetting: '告警设置',
		AlarmType: '告警类型',
		Alarmlevel: '告警级别',
		Alarmlevels: '告警等级',
		Alarmtime: '告警时间',
		alarmstatus: '告警状态',
		Recovered: '已恢复',
		alarmli: '告警中',
		name: '名称',
		Serverofflinealarm: '服务器离线告警',
		Networkalarm: '网络告警',
		Serverprocessstatusalarm: '服务器进程状态告警',
		CPUalarm: 'cpu告警',
		Diskalarm: '磁盘告警',
		Memoryalarm: '内存告警',
		Severitylevel: '严重等级',
		describe: '描述',
		Sendingtime: '发送时间',
		recoverytime: '恢复时间',
		alarmmanagement: '告警管理',
		Pleaseselectalarmtype: '请选择告警类型',
		Pleasefillinthealarmdescription: '请填写告警描述',
		Pleaseselectalarmlevel: '请选择告警等级',
		Lowerthreshold: '阈值下限',
		Upperthreshold: '阈值上限',
		Pleaseenterthelowerthreshold: '请输入阈值下限',
		Pleaseentertheupperthreshold: '请输入阈值上限',
		Pleaseenterapositiveintegerfrom0to100: '请输入0~100的正整数',

		Devicestatus: '设备状态',
		Operationtype: '运营类型',
		Sold: '已销售',
		Forsale: '待销售',
		livebroadcast: '直播',
		Losslessrecordingandbroadcasting: '无损录播',
		filetransfer: '文件传输',
		binding: '捆绑',
		Unbundling: '解绑',
		Unbundlings:'解绑成功',
		Equipmentmodel: '设备型号',
		Outputstrategy: '输出策略',
		Qualityfirst: '质量优先',
		Delaypriority: '延时优先',
		delay: '超低延时',
		buffer: '最大缓冲',
		Bitratemode: '码流模式',
		Codingsystem: '编码制式',
		Encodingframerate: '编码帧率',
		Fullframerate: '全帧率',
		Halfframerate: '半帧率',
		Equipmentupgrade: '设备升级',
		Themaximumnumber: '超过最大可上传数！',
		Selectfile: '选择文件',
		Forcedupgradeafterupload: '更新后自动升级',
		Latestversionnumber: '最新版本号',
		mailbox: '邮箱',
		Pleaseinputemail: '请输入邮箱',
		Areyousureyouwanttounbind: '确定要解除与该设备的捆绑关系吗',
		Areyousureyouwanttodeletes: '确定要删除该设备吗',
		Pleaseentertheserialnumber: '请输入序列号',
		Pleaseinputcoderate: '请输入码率',
		Pleaseenterapositiveintegerfrom100to10240: '请输入100-10240的正整数',
		Pleaseinputbuffertime: '请输入缓冲时间',
		Pleaseenterapositiveintegerfrom0to30000: '请输入500-30000的正整数',
		Pleaseenterthedevicename: '请输入设备名称',
		Pleaseselectoperationtype: '请选择运营类型',
		Pleaseselectthedevicemodel: '请选择设备型号',
		Pleaseselectworkingmode: '请选择工作模式',
		Pleaseselecttheencodingalgorithm: '请选择编码算法',
		Pleaseselecttransportprotocol: '请选择传输协议',
		Pleaseselectoutputpolicy: '请选择输出策略',
		Pleaseselectthecoderatemode: '请选择码率模式',
		Pleaseselectthecodingsystem: '请选择编码制式',
		Pleaseselectencodingframerate: '请选择编码帧率',
		Pleaseenterthelatestversionnumber: '请输入最新版本号',
		Bundledusers: '捆绑用户',
		Unbindingusers: '解绑用户',
		Editingdevice: '编辑设备',
		Removedevice: '删除设备',
		Uploadsucceeded: '上传成功',
		Areyousureyouwanttoremove: '确定移除',
		Thesizeoftheuploadedfile: '上传的文件大小不能超过',
		Usermailboxcannotbeempty: '用户邮箱不能为空',
		Deviceserialnumbercannotbeempty: '设备序列号不能为空',
		Workingparameters: '工作参数',

		Softwareupgrade: '软件升级',
		Inputfilename: '输入文件名称',
		Software: '软件',
		Generationtime: '版本号',
		Modelofequipmentused: '版本对应的时间戳',
		Forceupgrade: '时间戳',
		versions:'最低版本要求',
		yes: '是',
		no: '否',
		Selectmodel: '选择型号',
		Pleaseselectupgradefile: '请选择升级文件',
		Medialist: '媒体列表',

		Deviceofflinealarm: '设备离线告警',
		devicemanagement: '设备管理',
		Equipmentlist: '设备列表',
		Devicelog: '设备日志',
		Equipmentupgrademanagement: '设备升级管理',
		Servermanagement: '服务器管理',
		Logmanagement: '日志管理',
		Contractmanagement: '合约管理',
		Leasecontract: '租赁合约',
		Salescontract: '销售合约',
		Servicecontract: '服务合约',
		Medialibrary: '媒体库',
		Rolemanagement: '角色管理',
		Rolelist: '角色列表',
		usermanagement: '用户管理',
		Cloudadministrator: '云管理员',
		Deviceusers: '设备用户',
		Workordermanagement: '工单管理',
		Allworkorders: '全部工单',

		Logtype: '日志类型',
		Operationlog: '操作日志',
		systemlog: '系统日志',
		Latestlogtime: '最新日志时间',
		Logdetails: '日志详情',
		Operator: '操作人账号',
		Operationtime: '操作时间',
		pedagogicaloperation: '操作描述',

		Addrole: '添加角色',
		Editorialrole: '编辑角色',
		addsuccess: '添加成功',
		Editorsuccess: '编辑成功',
		deletessuccess: '删除成功',
		CanceledDelete: '已取消删除',
		Rolename: '角色名称',
		Roledescription: '角色描述',
		Permissionselection: '权限选择',
		Pleasefillintherolename: '请填写角色名称',
		Pleaseselectpermission: '请选择权限',
		Tips: '提示',
		Thisoperationwillpermanentlydelete: '此操作将永久删除该角色, 是否继续?',

		role: '角色',
		Addadministrator: '添加管理员',
		Editoradministrator: '编辑管理员',
		Pleaseentertheadministratorname: '请输入管理员名称或邮箱',
		fullname: '姓名',
		cellphonenumber: '手机号',
		Isitonline: '是否在线',
		VerificationCode: '验证码',
		Pleaseentertheverificationcode: '请输入验证码',
		Sendverificationcode: '发送验证码',
		Pleaseinputmobilephonenumber: '请输入手机号码',
		Pleasecompletetheinformation: '请完善信息',
		Thisoperationdeletetheadministrator: '此操作将永久删除该管理员, 是否继续?',
		Thisoperationdeletetheadministrators: '此操作将永久删除该文件, 是否继续?',

		accountnumber: '账号',
		Numberofonlinedevices: '在线设备数',
		Pleaseenterausernameoremailaddress: '请输入用户名或邮箱',
		Totalequipment: '总设备数',
		Totalalls: '在线设备数/总数',
		Lastlogintime: '最后登录时间',
		Disable: '禁用',
		Enable: '启用',

		Assigned: '已分派',
		Closed: '已关闭',
		Tobeclosed: '待关闭',

		Workorderdetails: '工单详情',
		Problemdescription: '问题描述',
		reply: '回复',
		Answerquestions: '回答提问',
		Pleaseinputthecontent: '请输入内容',
		Pleasecompletetheworkorderinformation: '请完善工单信息',

		Equipmentparameters: '设备参数',
		AfteryouwillunbinditAreyousure: '删除设备后，你将和该设备解除绑定关系， 确定要删除吗',
		Confirmadd: '确认添加',
		Addaddress: '添加地址',
		Savetocommonstreamingaddress: '保存到常用推流地址',
		Selectaddress: '选择地址',
		Thenameofpushstreamisrepeated: '推流名称重复了',
		Thestreamingaddressisrepeated: '推流地址重复了',


		Areyousureyouwanttodeletethefile: '确定要删除文件',
		Isthatright: '吗',
		Itwillnotberetrievedafterdeletion: '删除后将不可找回',
		video: '视频',
		picture: '图片',
		audiofrequency: '音频',
		Copysucceeded: '复制成功',
		copyfailed: '复制失败',

		Ordersent: '已派单',
		Mynickname: '我的昵称',
		Univisocloudliveservice: 'Univiso云直播售后服务',
		enclosure: '附件',
		Addfile: '添加图片',

		Problemsrelatedtolivevideoondemand: '视频点播直播相关问题',
		Problemsrelatedtostreamingmediapush: '流媒体推送相关问题',
		Userloginregistration: '用户登录注册、手机认证、找回用户名/密码等相关问题',

		Defaultregisteredmobilephone: '默认绑定手机号，可修改',
		Defaultregisteredmobileemail: '默认注册邮箱，可修改',
		Contactemail: '联系邮箱',

		headportrait: '头像',
		OnlyJPGandPNGformatsaresupported: '仅支持JPG、PNG、JPEG格式，文件小于2M（方形图）',
		Uploadnewimage: '上传新头像',
		Nickname: '昵称',
		Pleaseenteranickname: '请输入昵称',
		Pleaseenterthephonenumbertobebound: '请输入需要绑定的手机号',
		Pleaseinputthecorrectmobilephonenumber: '请输入正确格式的手机号',
		Uploadavatarimagecanonlybe: '上传头像图片只能是',
		format: '格式！',
		Thenumberofpicturesuploadedcannotexceed: '上传的图片不能超过',

		ChangePassword: '修改密码',
		Quotabalance: '配额余额',
		Streamingscheme: '推流方案',
		setup: '设置',
		signout: '退出',
		Servicedeadline: '服务截止时间',
		Originalpassword: '原密码：',
		Newpassword: '新密码：',
		Confirmpassword: '确认密码：',
		Logout: '退出登录',
		Doyouwanttologout: '是否退出登录',
		Pleaseentertheoriginalpassword: '请输入原密码',
		Pleaseenteranewpassword: '请输入新密码',
		Pleaseentertheconfirmationpassword: '请输入确认密码',
		Pleaseentertheconfirmationpassworda:'两次密码不一样请重新输入',
		Storagebalance: '存储余额：',
		Used: '已使用',
		Remainingstoragespace: '剩余存储空间',
		Flowbalance: '流量余额：',
		Residualflow: '剩余流量',

		Pleaseenterauniqueserialnumber: '请输入唯一序列号',
		Pleaseenteraname: '请输入名称',
		Pleaseentertheaddress: '请输入地址',
		Selectcommonstreamingaddress: '选择常用推流地址',
		Nocommonstreamingaddress: '暂无常用推流地址',
		Gotoadd: '前往添加',
		yunwei: '运维',
		Successfullyunbound: '成功解绑',
		Confirmimport: '确定导入',
		Noschemetoimport: '暂无可导入方案',
		sliceoimport: '请选择方案',
		Importsucceeded: '导入成功',
		Range: '范围',
		Setsuccessfully: '设置成功',

		Inputinterface: '输入接口',
		Inputsource: '输入源',
		FrameRateModel: "帧率模式",

		BackHome: '返回首页',
		AddScheme: '添加方案',
		SchemeName: '方案名称',
		SaveScheme: '保存方案',
		DeleteScheme: '删除方案',
		FrequentlyStreamAddress: '常用推流地址',
		SaveAddress: '保存地址',
		Total: "共",
		Strip: '条',
		PasswordNotMatch: "您的新密码两次输入的不一致",
		tableNull: "暂无数据",
		ThisActionPermanentlyDeletesTheSchema: '此操作将永久删除该方案!',
		WhetherOrNotToContinue: '是否继续?',
		ThisActionPermanentlyDeletesTheAddress: '此操作将永久删除该地址,',
		OnlyLiveBroadcastingModeCanBeIncluded: "仅直播工作模式才能收录",
		total: "总量",
		InputSource: '输入源',
		urlsArr: "无",
		WorkingHours: '工作时长',
		TotalStorage: '总存储量',
		TotalFLow: '总流量',
		Left: '剩余',
		CannotEdit: '离线状态不能操作',
		defaultPassword: '默认密码',
		defPassword: '请输入6-16位字母加数字组合密码',
		battery: '无电池',
		again: '修改密码成功，即将重新登录',
		Theserial: '序列号必须是16位或32位',
		free: '空闲',
		free_a: '推流正常',
		free_b: '推流异常',
		free_c: '初始化失败',
		free_d: '初始化正常',
		recordedbroadcast:"录播已开始",
		recordedbroadcasts:'录播未开始',
		livebro:'直播未开始',
		livebros:'直播已开始',
		pulusfuwuq:'请至少添加一台终端设备或者服务器',
		paymentdays:"账期",
		IDs:"账号",
		billnumber:"账单号",
		Thebilltype:"账单类型",
		consumptiontime:"消费时间",
		targetIp:"目标IP",
		unitprice:"单价",
		unitpriceUnid:"单价单位",
		dosage:"用量",
		dosageUnid:"用量单位",
		balance:"应付金额",
		paymentamount:"支付金额",
		Owetheamount:"欠费金额",
		stateofpayment:"支付状态",
		dischargepattern:"流量消耗类型",
		oderId:'订单ID',
		ordernumber :'订单编号',
		ordertype :'订单类型',
		ordertypes :'订单类型',
		bankNumber :'账号',
		timeofpayment: "支付时间",
		rechargeamount: "充值金额",
		patternofpayment: "支付方式",
		Wechatpayment:'微信支付',
		Alipaypayment:'支付宝支付',
		orderstatus : "订单状态",
		odertexts:'请输入订单编号',
		obligation:'待付款',
		lostefficacy:"已失效",
		canceled:"已取消",
		posoname:"请输入用户账号或账单号",
		centers:"流量消耗类型",
		la:"拉流",
		tui:"推流",
		pullzip:"文件下载",
		PersonalCenter:'个人中心',
		Balancerecharge:'余额充值',
		Balancerecharges:'余额充值',
		balances:'余额',
		accountoverview:'账户总览',
		Ordercenter:'订单中心',
		Ordercenters:"订单",
		billingcenter:'账单中心',
		billingcenters:'账单',
		accountsettings:'账户设置',
		accountbalance:'账户余额',
		returnhome:'返回首页',
		Checkaccount:'核对账号和填写金额',
		Confirmandpay:'确认并支付',
		complete:'完成',
		Rechargeaccount:'充值账号',
		Currentbalance:'当前余额',
		Fastrecharge:'快速充值',
		Otheramounts:'其他金额',
		Rechargerecord:'充值记录可前往',
		see:'查看',
		Rechargenow:'立即充值',
		Rechargenows:'立即充值',
		Ordersubmitted:'订单提交成功，请尽快付款',
		Pleasecompletepayment:'请您再提交订单后60分钟内完成支付，否则订单会自动取消',
		Congratulations:'恭喜您，充值成功!',
		Rechargetime:'充值时间',
		Topay:'去支付',
		billingcycle:'账单周期',
		Flowbill:'流量账单',
		Storebills:'存储账单',
		Billdetails:'账单详情',
		Mediafiles:'媒体文件',
		Changetype:'变化类型',
		Changedosage:'变化用量',
		Totalstorages:'存储总量',
		Flowconsumption:'流量消耗类型',
		route:'路径',
		mailboxss:'邮箱或手机号',
		Pleaseinputemailss:'请输入邮箱或手机号',
		Pleaseinputemailssa:'请输入正确的邮箱或手机号',
		package:'请上传正确的文件包格式',
		Uploadsucceededs:'上传失败',
		Clickstart:'点击开始直播',
		Clickend:'点击结束直播',
		Clickstarts:'点击开始录播',
		Clickends:'点击结束录播',
		Signal:'设备离线',
		Signals:'信号丢失',
		Startrecording:'开始收录',
		endrecording:'结束收录',
		copy:'复制',
		Noequipment:'暂无设备',
		Livename:'直播名称',
		Recordingname:'录播名称',
		myword:'我的',
		Youcanpreview:'只有在开启直播的状态下才可以预览',
		canonlyberecorded:'只有在开启直播状态下才可以收录',
		Turnonlive:'开启直播',
		Turnonlives:'关闭直播',
		Startrecordings:'开启录播',
		Startrecordingsa:'关闭录播',
		Operations:'操作成功',
		userid:'用户账号',
		Noversion:'无可用版本升级包',
		Upgradepackage:'升级包损坏',
		Thereisan:'服务端存在时间戳更新版本的升级包',
		Theupgrade:'升级包型号不识别',
		Upgrademodule:'升级模块故障',
		Thereareupgrade:'服务端存在相同版本的升级包',
		Illegalrequest:'请求参数不合法',
		Failedtoupload:'上传文件失败',
		Theupgrades:'升级包无效',
		Submitted:'提交成功',
		Exitsuccessful:'退出成功',
		Pleaseenter:'请输入正确的推流地址',
		Sentsuccessfully:'发送成功',
		Thisaccount:'该账号暂未绑定手机，绑定后您可通过 手机号直接登录，是否立即绑定？',
		Nextlogin:'下次登录不在提示',
		bindings:'绑定',
		bindingsa:'绑定成功',
		onlythebit:'提示：在工作模式下基础设置只能修改码率和缓冲时间',
		twothebit:'提示：在工作模式下不可修改音视频编码参数',
		Programname:'节目名称',
		Programsize:'节目大小',
		coordinate:'坐标',
		Selectall:'全选',
		Batchdelete:'确定要批量删除选中的文件吗？',
		Batchdeletes:'批量删除',
		Pleaseselectdelete:'请选择一个文件进行删除',
		streamingAddressaa:'推流地址',
		SchemeNamessa:'方案名称',
		Pleaseenters:'请输入500-30000的数字',
		Pleaseentersa:'请输入100-15360的数字',
		Confirmadds:'确认添加',
		addsuccessaa:'已保存到常用推流地址',
		addsuccessbb:'添加推流地址成功',
		Accountlogin:'账号登录',
		Codescanninglogin:'扫码登录',
		Mobilenumberlogin:'手机号登录',
		Pleaseloginpassword:'请输入登录密码',
		Noaccount:'没有账号',
		Registernow:'立即注册',
		Signin:'登 录',
		Pleaseenternumber:'请输入账号',
		Pleaseenternumbers:'请输入密码',
		Pleaseenternumbersb:'请确认密码',
		Pleaseenternumbersa:'请输入账号和密码',
		Thisadministrator:'该账号已禁用,请联系管理员',
		Pleaseslideverify:'请滑动滑块验证',
		Pressandholdright:'请按住滑块，拖动到最右边',
		Verificationpassed:'验证通过',
		Returntologin:'返回登录',
		Bindaccount:"绑定账号",
		Accountregistration:'账号注册',
		Codescanningregistration:'扫码注册',
		Existingaccount:"已有账号",
		Loginnow:'立即登录',
		Wechatregistration:'微信注册',
		loginwassuccessful:'注册成功',
		Thisaccounthasbeen:'该账号已注册，请到登录页登录',
		Codingpreview:'目前不支持H.265的编码预览',
		Booting:'正在开机',
		Onlyentered:'只能输入0-9,A-Z,a-z,下划线,减号',
		Areyousure:'您确定要',
		Upcharacters:'最多可输入256个字符',
		Pleaseenterthecorrects:'请输入正确的推流地址',
		filesizes:'文件大小',
		fileNames:'文件名称',
		close:'关闭',
		Arrearagereminder:'欠费提醒',
		balanceenough:'余额不足',
		balanceisinsufficient:"尊敬的客户，您当前余额不足，为了不影响后续使用，请尽快充值！",
		Streamingschemes:'推流方案',
		accountbalances:'账户余额',
		accountarrears:'尊敬的客户，您当前账户已欠费，为了不影响您的后续使用，请尽快进行充值！',
		accountarrearsa:'尊敬的客户，您当前账户已欠费停机，为了不影响您的后续使用，请尽快进行充值!',
		Pleaseenterpositive:'请输入小于等于1000正整数',
		Pleaseenterpositives:'请输入正整数金额',
		Amountpayable:'应付金额',
		Paywechatcode:'使用微信扫码付款',
		thlisttime:'注：列表展示最近一年的订单记录，超过一年的订单记录可通过时间查询',
		query:'查询',
		Querybymonth:'月账单',
		Querybyyear:'年账单',
		onlythedetails:'注：仅支持查看最近三个月的账单详情，超过三个月只能查看总账单',
		Billflow:'账单流水',
		Pleasebillnumber:'请输入账单号',
		increase:'增加',
		reduce:'减少',
		Paid:'已支付',
		Unpaid:'未支付',
		Rechargereminder:'充值提醒',
		Currentaccount:'当前账号余额为',
		Pleaserecharge:'请充值后再操作',
		Clickrecharge:'点击立即充值',
		operationwill:'此操作将取消该订单, 是否继续?',
		Storagepeak:'存储峰值',
		Storeamount:'存储预估金额',
		Storagepeakmonth:'本月存储峰值',
		Currentusage:'当前存储占用量',
		Accountsbalance:'账户余额',
		Basicsettings:"基础设置",
		Audioandvideo:"音视频编码设置",
		Logosettings:"LOGO设置",
		Videoframeratemode:"视频帧率模式",
		Videoresolution:"视频分辨率",
		Videorotationangle:"视频旋转角度",
		VIDEODEinterleaving:"视频反交错",
		Audiocodingmode:"音频编码模式",
		Audiocodingrate:"音频编码码率",
		Audirate:"音频编码采样率",
		switch:"开关",
		imgfenbian:"图片分辨率不能大于400x300",
		resolvingpower:"分辨率",
		Picturename:"图片名称",
		width:"宽",
		height:"高",
		Keephomology:"保持同源",
		automatic:"自动",
		Framerate:"帧率",
		Rotationangle:"旋转角度",
		deinterlacing:"反交错",
		open:"开启",
		close:"关闭",
		Audiomode:"音频模式",
		Audiobitrate:"音频码率",
		Audiosamplingrate:"音频采样率",
		Norotation:"不旋转",
		Rotateone:"旋转90°",
		Rotatetwo:"旋转180°",
		Rotatether:"旋转270°",
		Addpackage:"添加套餐",
        Packagename:"套餐名称",
        starttime:"有效日期",
        deadline:"截止时间",
        Months:"月数",
        sort:"排序",
        Costunit:"费用单位",
        Costunitprice:"单价",
        Discountfee:"折扣费用",
        Flowlimit:"流量限额",
        storagequota:"存储限额",
        Excesstrafficstrategy:"流量超额策略",
        Additionalbilling:"附加计费",
        OutofService:"停止服务",
        danjiafujia:"附加流量单价",
        cucunxiane:"存储限额超额策略",
        fujiacucen:"附加储存单价",
        Purchasetips:"购买须知",
        Serviceinstructions:"服务须知",
		removeserver: '确定要删除该套餐吗？',
		Deletestaocan: '删除套餐',
		Excessstrategy: '超额策略',
		Promptinformation: '提示信息',
		accessPort:"请输入正确的货币单位",
		maxprices:"价格必须大于0",
		maxprceaaa:"价格最大为9999",
		maxnumbers:"最大值为5000",
		xianerices:"限额必须大于0",
		addnames:"请输入套餐名称",
		datamesg:"请输入时间",
		alldatamesg:"请选择时间范围",
		zekomesgs:"请输入折扣费用",
		feiyongmesg:"请输入费用单位",
		danjialsit:"请输入单价",
		liuxianes:"请输入流量限额",
		cunxianes:"请输入存储限额",
		chaoecel:"请选择超额策略",
		PackageCenter:"套餐中心",
		Timeofpurchase:"购买时间",
		Buynow:"立即购买",
		Packagemall:"套餐商城",
		Mypackage:"我的套餐",
		Totalcost:"合计费用",
		Ihaveread:"我已阅读",
		already:"我已知晓",
		woyuedu:"请详细阅读",
		xiangxiyuedule:"《用户服务须知》",
		firstname:"请先阅读服务须知",
		taocanlsit:"套餐列表",
		xiaofeilieo:"消费列表",
		shijia:"套餐实际价格",
		zongjia:"套餐总价",
		addimage:"请添加LOGO图片",
		youhuijiafe:"优惠价格必须小于(单价*月份)",
		SetRouting:"设置路由",
		restart:"重启",
		Networkmode:"网络模式",
	}
}