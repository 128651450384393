<template>
  <div :class="lang==true?'backgroundColor':'backgroundColor_a'">
    <router-view />
  </div>
</template>

<script>
export default {
	data(){
		return{
			lang:true
		}
	},
  created() {
		let sesw=setInterval(()=>{
			if(sessionStorage.getItem('language')){
				clearInterval(sesw)
			    if (sessionStorage.getItem('language') == "zhong") {
			      this.lang=true
			    } else {
			      this.lang=false
			    }
			}
		},100)
	},
  mounted() {},
};
</script>

<style lang="less" scpoed>
.backgroundColor {
  width: 100%;
  height: 100%;
  background-image: url("../assets/imgs/bj.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.backgroundColor_a{
	width: 100%;
	height: 100%;
	background-image: url("../assets/imgs/bj3.jpg");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
</style>
