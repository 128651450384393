import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
/* import Token from '../api/apis' */
//全局注册使用
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

var router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'login',
			redirect: 'userlogin',
			component: Login,
			children: [
				{
					path: '/userlogin',
					name: '',
					component: () => import('../views/login/UserLogin.vue'),
				},
				{
					path: '/userreg',
					name: 'userreg',
					component: () => import('../views/login/reg/UserReg.vue'),
				},
				{
					path: '/forgetpas',
					name: 'forgetpas',
					component: () => import('../views/login/forgetpas/ForgetPas.vue'),
				},
				{
					path: '/userlocking',
					name: 'userlocking',
					component: () => import('../views/login/userlocking/UserLocking.vue'),
				},
				{
					path: '/userunlock',
					name: 'userunlock',
					component: () => import('../views/login/userunlock/UserUnlock.vue'),
				}
			]
		},
		{
			path: '/nav',
			name: 'nav',
			redirect: '/nav/manage/devicelist',
			component: () => import('../views/Nav'),
			children: [
				{
					path: '/nav/user',
					name: 'nav/user',
					redirect: '/nav/user/equipment',
					component: () => import('../views/nav/User.vue'),
					children: [
						{
							path: '/nav/user/equipment',/* 用户端 */
							name: 'nav/user/equipment',
							component: () => import('../views/nav/user/equipment/Equipment.vue'),
						},
						{
							path: '/nav/user/equipmentmsg',
							name: 'nav/user/equipmentmsg',
							component: () => import('../views/nav/user/equipment/EquipmentMsg.vue'),
						},
						{
							path: '/nav/user/equipmentList',
							name: 'nav/user/equipmentList',
							component: () => import('../views/nav/user/equipment/equipmentList.vue'),
						},
						{
							path: '/nav/user/media',
							name: 'nav/user/media',
							component: () => import('../views/nav/user/media/Media.vue'),
						},
						{
							path: '/nav/user/journal',
							name: 'nav/user/journal',
							component: () => import('../views/nav/user/journal/Journal.vue'),
						},
						{
							path: '/nav/user/journaldetails',
							name: 'nav/user/journaldetails',
							component: () => import('../views/nav/user/journal/JournalDetails.vue'),
						},
						{
							path: '/nav/user/workorder',
							name: 'nav/user/workorder',
							component: () => import('../views/nav/user/workorder/WorkOrder.vue'),
						},
						{
							path: '/nav/user/choiceorder',
							name: 'nav/user/choiceorder',
							component: () => import('../views/nav/user/workorder/ChoiceOrder.vue'),
						},
						{
							path: '/nav/user/ordermsg',
							name: 'nav/user/ordermsg',
							component: () => import('../views/nav/user/workorder/OrderMsg.vue'),
						},
						{
							path: '/nav/user/submitorder',
							name: 'nav/user/submitorder',
							component: () => import('../views/nav/user/workorder/SubmitOrder.vue'),
						},
						{
							path: '/nav/user/workdetails',
							name: 'nav/user/workdetails',
							component: () => import('../views/nav/user/workorder/WorkDetails.vue'),
						}
						,
						{
							path: '/nav/user/users',
							name: '/nav/user/users',
							component: () => import('../views/Users'),
						},
						{
							path: '/nav/user/pushscheme',
							name: 'nav/user/pushscheme',
							component: () => import('@/views/nav/user/PushScheme.vue'),
						},
						{
							path: '/nav/user/personal',
							name: 'nav/user/personal',
							component: () => import('@/views/nav/user/personal.vue'),
						},
					]
				},
				{
					path: '/nav/users',
					name: 'nav/users',
					component: () => import('../views/Users'),
				},
				{
					path: '/nav/quotaBalance',
					name: 'quotaBalance',
					component: () => import('@/views/nav/user/quotaBalance'),
				},
				{
					path: '/nav/pushscheme',
					name: 'nav/pushscheme',
					component: () => import('@/views/nav/user/PushScheme.vue'),
				},
				{
					path: '/nav/personal',
					name: 'nav/personal',
					component: () => import('@/views/nav/user/personal.vue'),
				},
				{
					path: '/nav/manage',
					name: 'nav/manage',
					redirect: '/nav/manage/devicelist',
					component: () => import('../views/nav/Manage.vue'),
					children: [
						{
							path: '/nav/manage/role',/* 管理端 */
							name: 'nav/manage/role',
							component: () => import('@/views/nav/manage/role/Role.vue'),
						},
						{
							path: '/nav/manage/cloud',
							name: 'nav/manage/cloud',
							component: () => import('../views/nav/manage/users/Cloud.vue'),
						},
						{
							path: '/nav/manage/userequilist',
							name: '/nav/manage/userequilist',
							component: () => import('../views/nav/manage/users/userequilist.vue'),
						},
						{
							path: '/nav/manage/facility',
							name: 'nav/manage/facility',
							component: () => import('../views/nav/manage/users/Facility.vue'),
						},
						{
							path: '/nav/manage/devicelist',
							name: 'nav/manage/devicelist',
							component: () => import('../views/nav/manage/device/DeviceList.vue'),
						},
						{
							path: '/nav/manage/equipmentfile',
							name: 'nav/manage/equipmentfile',
							component: () => import('../views/nav/manage/device/EquipmentFile.vue'),
						},
						{
							path: '/nav/manage/devupgrade',
							name: 'nav/manage/devupgrade',
							component: () => import('../views/nav/manage/device/devupgrade.vue'),
						},
						{
							path: '/nav/manage/equipmentmssg',
							name: 'nav/manage/equipmentmssg',
							component: () => import('../views/nav/manage/device/EquipmentMssg.vue'),
						},
						{
							path: '/nav/manage/deviceJournal',
							name: 'nav/manage/deviceJournal',
							component: () => import('../views/nav/manage/device/DeviceJournal.vue'),
						},
						{
							path: '/nav/manage/alarm',
							name: 'nav/manage/alarm',
							component: () => import('../views/nav/manage/device/Alarm.vue'),
						},
						{
							path: '/nav/manage/warning',
							name: 'nav/manage/warning',
							component: () => import('../views/nav/manage/servermge/Warning.vue'),
						},
						{
							path: '/nav/manage/onlinelist',
							name: 'nav/manage/onlinelist',
							component: () => import('../views/nav/manage/servermge/OnlineList.vue'),
						},
						{
							path: '/nav/manage/serverlist',
							name: 'nav/manage/serverlist',
							component: () => import('../views/nav/manage/servermge/ServerList.vue'),
						},
						{
							path: '/nav/manage/newlyserver',
							name: 'nav/manage/newlyserver',
							component: () => import('../views/nav/manage/servermge/NewlyServer.vue'),
						},
						{
							path: '/nav/manage/serverdetails',
							name: 'nav/manage/serverdetails',
							component: () => import('../views/nav/manage/servermge/ServerDetails.vue'),
						},
						{
							path: '/nav/manage/terminallist',
							name: 'nav/manage/terminallist',
							component: () => import('../views/nav/manage/servermge/TerminalList.vue'),
						},
						{
							path: '/nav/manage/leaselist',
							name: 'nav/manage/leaselist',
							component: () => import('../views/nav/manage/contract/leaselist.vue'),
						},
						{
							path: '/nav/manage/saleslist',
							name: 'nav/manage/saleslist',
							component: () => import('../views/nav/manage/contract/saleslist.vue'),
						},
						{
							path: '/nav/manage/servicelist',
							name: 'nav/manage/servicelist',
							component: () => import('../views/nav/manage/contract/servicelist.vue'),
						},
						{
							path: '/nav/manage/serverdaily',
							name: 'nav/manage/serverdaily',
							component: () => import('../views/nav/manage/servermge/ServerDaily.vue'),
						},
						{
							path: '/nav/manage/workmsg',
							name: 'nav/manage/workmsg',
							component: () => import('@/views/nav/manage/workmsg/WorkMsg.vue'),
						},
						{
							path: '/nav/manage/orderdetails',
							name: 'nav/manage/orderdetails',
							component: () => import('@/views/nav/manage/workmsg/OrderDetails.vue'),
						},
						{
							path: '/nav/manage/mediamge',
							name: 'nav/manage/mediamge',
							component: () => import('@/views/nav/manage/mediamge/Mediamge.vue'),
						},
						{
							path: '/nav/manage/billManagement',
							name: 'nav/manage/billManagement',
							component: () => import('../views/nav/manage/billManagement/billManagement.vue'),
						},
						{
							path: '/nav/manage/orderManagement',
							name: 'nav/manage/orderManagement',
							component: () => import('../views/nav/manage/orderManagement/orderManagement.vue'),
						},
						{
							path: '/nav/manage/storethebill',
							name: 'nav/manage/storethebill',
							component: () => import('../views/nav/manage/storethebill/storethebill.vue'),
						},
						{
							path: '/nav/manage/stordet',
							name: 'nav/manage/stordet',
							component: () => import('../views/nav/manage/storethebill/stordet.vue'),
						},
						{
							path: '/nav/manage/stordets',
							name: 'nav/manage/stordets',
							component: () => import('../views/nav/manage/storethebill/stordets.vue'),
						},
						{
							path: '/nav/manage/billdetail',
							name: 'nav/manage/billdetail',
							component: () => import('@/views/nav/manage/billdetail/billdetail.vue'),
						},
						{
							path: '/nav/manage/bildets',
							name: 'nav/manage/bildets',
							component: () => import('@/views/nav/manage/billManagement/bildets.vue'),
						},
						{
							path: '/nav/manage/packagelist',
							name: 'nav/manage/packagelist',
							component: () => import('@/views/nav/manage/packagelist/packagelist.vue'),
						},
						{
							path: '/nav/manage/packagelistdetail',
							name: 'nav/manage/packagelistdetail',
							component: () => import('@/views/nav/manage/packagelist/packagelistdetail.vue'),
						},
						{
							path: '/nav/manage/components',
							name: 'nav/manage/components',
							component: () => import('@/views/nav/manage/pagecomponents/pagecomponents.vue'),
						},
					]
				}
			]
		},

	]
})

export default router