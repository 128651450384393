/**
 * Created by Andste on 2018/5/7.
 */
import { MessageBox } from 'element-ui'
/**
 * 检查token：
 * 1. user/accessToken都存在。
 *    表示所有条件满足，放行所有API
 * 2. 不存在accessToken，但是user/refreshToken存在。
 *    表示accessToken过期，需要重新获取accessToken。
 *    如果重新获取accessToken返回token失效错误，说明已被登出。
 * @param options
 * @returns {Promise<any>}
 */
export default function checkToken() {
  // user
  const user = sessionStorage.getItem('user')
  // 访问Token
  const accessToken = sessionStorage.getItem('token')
  // 返回异步方法
  return new Promise((resolve) => {
    /**
     * 如果accessToken、user都存在。
     * 说明必要条件都存在，可以直接通过，并且不需要后续操作。
     */
    if (accessToken && user) {
      resolve()
      return
    }
		/**
		 * 如果accessToken或者user没有
		 * 说明登录已失效、或者cookie有问题，需要重新登录。
		 */
		/* if (!accessToken) {
			window.location.replace(`/`)
			return
		} */
    resolve()
  })
}
