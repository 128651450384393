module.exports = {
  lang: {
    LanguageTransform: 'Language Transform',
    helloworld: 'Hello World',
    vbs: 'You can add up to four streaming addresses',
    forgetpassword: 'Forget Password',
    equipmentName: 'Device Name',/* 设备板块 */
    equipment: 'Device',
    media: 'Media',
    Workorder: 'Work Order',
    journal: 'Log',
    hardwareVersion: 'Hardware Version',
    softwareVersion: 'Software Version',
    startRecording: 'Start Reording',
    stopRecording: 'Stop Recording',
    transmissionRate: 'Transmission Rate',
    codeRate: 'Encoding Rate',
    inputSystem: 'Input System',
    electricitySurplus: 'State Of Charge',
    diskSpace: 'Hard Disk Space',
    bitRate: 'Bitrate',
    delayed: 'Delay',
    delaySets: 'Delay setting',
    streamingAddress: 'Streaming Address',
    Pullflowaddress: 'Pull flow address',
    importScheme: 'Import mode',
    showPushStreamName: 'Straming Name Display',
    codingAlgorithm: 'Encoding Algorithm',
    transportProtocol: 'Transport Protocol',
    transmissionStrategy: 'Transport Strategy',
    bitstreamMode: 'Stream mode',
    delayTime: 'Delay Time',
    bufferTime: 'Buffer Time',
    resolvingPowert: 'Resolution',
    cancel: 'Cancel',
    submit: 'Submit',
    basicInformation: 'Basic Information',/* 设备信息 */
    model: 'Model',
    workingMode: 'Work Mode',
    workingHours: 'Work Hours',
    realTimeBitRate: 'Current Bitrate',
    rateControl: 'Bitrate Control',
    workingCondition: 'Work Status',
    onLine: 'Online',
    individual: '',
    available: 'Available',
    common: 'Coomon',
    time: 'Time',
    type: 'Type',
    selectDate: 'Select Date',
    search: 'Search',
    detailedInformation: 'Details',
    nameModification: 'Rename',
    copyAddress: 'Copy ',
    copyAddressa: 'Copy URL',
    fileName: 'Equipment type',
    creationTime: 'Creat Time',
    programDuration: 'Program Duration',
    sourceEquipment: 'Source Equipment',
    fileSize: 'Equipment Number',
    devdownload: 'download address',
    return: 'Return',
    submitWorkOrder: 'Submit Work Order',/* 工单 */
    workOrderStatus: 'Work Order status',
    to: 'To',
    pleaseSelect: 'Please Select',
    workOrderNo: 'Work Order No',
    NameModification: 'Problem description',
    problemDescription: 'Problem type',
    state: 'Status',
    submissionTime: 'Submit time',
    details: 'Detail',
    closeWorkOrder: 'Close',
    operation: 'Operation',
    pleaseEnterAFileName: 'Enter File Name',
    electWorkOrder: 'Select Work Order',
    writeWorkOrder: "Fill in Work Order",
    userRegistrationClass: 'Register Type',
    liveBroadcastTerminalClass: 'Device',
    streamingMediaPushClass: 'Streaming',
    /*copyAddress: '',
    copyAddress: 'Copy Adress',
    NameModification: 'Modify Adress',
    copyAddress: 'Copy Adress',
    copyAddress: 'Copy Adress',
    NameModification: 'Rename',
    NameModification: 'Rename',
    copyAddress: 'Copy Adress',
    copyAddress: 'Copy Adress', */


    AddServer: 'Add Server',
    ServerSerial: 'Sever SN',
    Please: 'Please Select',
    Cloudserver: 'Cloud Server',
    Localserver: 'Local Server',
    ServerDetails: 'Serer Details',
    EssentialInformation: 'Basic Information',
    Deleted: 'Forbidden',
    normal: 'Normal',
    nonormal: 'Locked',
    fault: 'Faulted',
    maintain: 'Maintenance',
    offline: 'Off Line',
    lease: 'Rental',
    sale: 'Sale',
    nucleus: 'Nucleus',
    ServerName: 'Server Name',
    ServerType: 'Server Type',
    Location: 'Location',
    ServerNetwork: 'Server Network Configration',
    RunningState: 'Running Status',
    OperateState: 'Operate Status',
    BusinessState: 'Business Process Status',
    LeaseTime: 'Terms of lease',
    OnlineTerminal: 'Online Device',
    ConfigureInformation: 'Configuration information',
    IpAddress: 'IP Address',
    VisitAddress: 'Visit IP',
    port: 'Port',
    ResourceStatus: 'Source Status',
    CpuNumber: 'Core of CPU',
    Cpuutilization: 'CPU Utilization',
    Occupiedbroadband: 'The current flow',
    Occupieddisk: 'Occupied Disk Space',
    Occupiedmemory: 'Occupied Memory',
    broadband: 'Bandwidth',
    disk: 'Disk',
    Memory: 'Memory',
    preservation: 'Save',
    Pleaseinputtheinformation: 'Please Enter Full Information',
    Theserialnumbercannot: 'The serial number cannot contain text or special characters',
    Pleaseinputthecorrect: 'CUP, Please enter an integer',
    Pleaseenterthecorrectsize: 'Broadband, Please enter an integer',
    Pleaseenterthecorrectdisk: 'Disk, please enter an integer',
    Pleaseenterthecorrectmemory: 'Memory, please enter an integer',

    servernameorsn: 'Please enter server name or SN',
    equipmentnameorsn: 'Please device server name or SN',
    whole: 'All',
    Generalalarm: 'General Warning',
    majoralarm: 'Significant Warning',
    Emergencyalarm: 'Emergency Warning',
    server: 'Server',
    networkconfiguration: 'Network Configuration',
    abnormal: 'Abnormal',
    Leasetime: 'Term of lease',
    edit: 'Edit',
    previouspage: 'Prevous Page',
    nextpage: 'Next Page',
    EditServer: 'Server Edit',
    PleaseIPaddress: 'Please enter the IP adress',
    Pleaseenterthecorrect: 'Please enter the correct IP adress',
    Pleaseentertheaccess: 'Please enter the visit address',
    Pleaseenterthecorrectaccess: 'Please enter the correct visit address',
    Pleaseentertheport: 'Please ienter nput the port',
    Pleaseenterthecorrectport: 'Please enter the correct port',
    Pleaseentertheserver: 'Please enter server name',
    Pleaseentertheregion: 'Please enter your region',
    Pleaseselectrunning: 'Please select running status',
    Pleaseselecttype: 'Please select type',
    Pleaseselectoperation: 'Please select operation status',

    PleaseenterthedevicenameorSn: 'Please enter the device name or SN',
    Portalserver: 'Portal server',
    logintime: 'Login time',
    Areyousureyouwanttodeletethisserver: 'Are you sure you want to delete this server',
    Deleteserver: 'Delete server',

    Addcontract: 'Add Contact',
    Pleaseinputkeywords: 'Please enter keyword',
    Nameoflessee: 'Name of Lessee',
    Targetaccountnumber: 'Target Account',
    Numberofterminals: 'Quantity of Device',
    Terminalserialnumber: 'Quantity ofSN ',
    Completed: 'Completed',
    Underimplementation: 'Actived',
    contacts: 'Contacts',
    Contactnumber: 'Telephone ',
    contactnumbers: 'Telephone ',
    fixedtelephone: 'Landline Telephone',
    address: 'address',
    Purchaseinformation: 'Purchase Information',
    Contractname: 'Contract Name',
    Buyertype: 'Buyer Type',
    naturalperson: 'Natural Person',
    EnterpriseV: 'EnterpriseV',
    Startdate: 'Start Date',
    Enddate: 'End Date',
    Selectdevice: 'Select Device',
    Selectserver: 'Select Server',
    Adddevice: 'Add Device',
    Customerinformation: 'Customer Information',
    Serverlist: 'Server List',
    serialnumber: 'SN',
    confirm: 'Confirm',
    Pleaseentercontactphone: 'Please enter contact phone number',
    Pleaseenterthecorrectcontactphone: 'Please enter correct contact phone number',
    Pleaseenterthecontractname: 'Please enter contract name',
    Pleaseselectthetypeofrentdebit: 'Please select the type of lessee',
    Pleaseenterleaseholder: 'Please select the name of lessee',
    Pleaseenterthetarget: 'Please enter target account',
    Pleaseenterthecorrectemail: 'Please enter the correct email address or mobile phone number',
    Pleaseselecttheleasetime: 'Please select term of lease',
    Pleaseentercontact: 'Please enter contact name',
    Addleasecontract: 'Add lease contract',
    Editcontract: 'Edit Contract',
    Contractdetails: 'Contract Detail',
    Pleaseselectthedevicefirst: 'Please select the device first',
    Pleaseselecttheserverfirst: 'Please select the server first',
    Pleaseselectadifferentdevice: 'Please select a different device',
    Pleaseselectadifferentserver: 'Please select a different server',
    Pleaseselectatleastonedevice: 'Add at least one device or server',
    Pleaseselectatleastonedevices: "Please add a device",
    mages: "-1 means the quota is unlimited",

    Buyersname: 'Purchaser',
    Pleaseenterthebuyersname: "Please inout the purchaser's name",
    Addsalescontract: 'Add contract',

    servicetime: 'Service time',
    Trafficquota: 'Quota of network traffic (KB)',
    storagequota: 'Quota of storage (MB)',
    Pleaseenterstoragequota: 'Please enter the quota of storage',
    Pleaseenterapositiveinteger: 'Please enter a positive integer containing 0',
    Pleaseinputtrafficquota: 'Please enter the quota of network traffic',
    Addservicecontract: 'Add contract',

    Pleaseselectthesourcedevice: 'Please select the source device',
    Pleaseenterthefilenameoruser: 'File name/user mailbox',
    User: 'User',
    download: 'Download',
    deletes: 'Delete',
    Areyousureyouwanttodelete: 'Are you sure you want to delete it',
    determine: 'Yes',
    Editfile: 'Edit file',
    Deletefile: 'Delete file',

    Alarmsetting: 'Alarm setting',
    AlarmType: 'Alarm type',
    Alarmlevel: 'Alarm level',
    Alarmlevels: 'Alarm level',
    Alarmtime: 'Alarm time',
    alarmstatus: 'Alarm status',
    Recovered: 'Recovered',
    alarmli: 'Alarming',
    name: 'Name',
    Serverofflinealarm: 'Sever offline alarming',
    Networkalarm: 'Network error alarm ',
    Serverprocessstatusalarm: 'Server process status alarm',
    CPUalarm: 'CPU alarm',
    Diskalarm: 'Hard-disk alarm',
    Memoryalarm: 'Memory alarm',
    Severitylevel: 'Level of severity',
    describe: 'Description',
    Sendingtime: 'Sending time',
    recoverytime: 'Recovery time',
    alarmmanagement: 'Alarm management',
    Pleaseselectalarmtype: 'Please select alarm type',
    Pleasefillinthealarmdescription: 'Please fill in the alarm description',
    Pleaseselectalarmlevel: 'Please select alarm level',
    Lowerthreshold: 'Min value of threshold',
    Upperthreshold: 'Max Value of threshold',
    Pleaseenterthelowerthreshold: 'Please enter the min value of threshold',
    Pleaseentertheupperthreshold: 'Please enter the max value of threshold',
    Pleaseenterapositiveintegerfrom0to100: 'Please enter positive integers from 0 to 100',

    Devicestatus: 'Device status',
    Operationtype: 'Operation type',
    Sold: 'Sold',
    Forsale: 'On sale',
    livebroadcast: 'Live Broadcast',
    Losslessrecordingandbroadcasting: 'Lossless record',
    filetransfer: 'File Transfer',
    binding: 'Binding',
    Unbundling: 'Unbinding',
    Equipmentmodel: 'Device model',
    Outputstrategy: 'Output strategy',
    Qualityfirst: 'Quality precedence',
    Delaypriority: 'Latency precedence',
    delay: '0 latency',
    buffer: 'Max Buffer',
    Bitratemode: 'Stream mode',
    Codingsystem: 'Encoding system',
    Encodingframerate: 'Encoding framerate',
    Fullframerate: 'Full Frame',
    Halfframerate: 'Half Frame',
    Equipmentupgrade: 'Upgrade',
    Themaximumnumber: 'Over the max upload quantity',
    Selectfile: 'Select file',
    Forcedupgradeafterupload: 'Force upgrade afer download',
    Latestversionnumber: 'Latest version number',
    mailbox: 'Email address',
    Pleaseinputemail: 'Please enter the email address',
    Areyousureyouwanttounbind: 'Are you sure you want to unbinding the device?',
    Areyousureyouwanttodeletes: 'Are you sure you want to delete the device?',
    Pleaseentertheserialnumber: 'Please enter the SN',
    Pleaseinputcoderate: 'Please inout the bitrate',
    Pleaseenterapositiveintegerfrom100to10240: 'Please enter a positive integer from 100 to 10240',
    Pleaseinputbuffertime: 'Please enter the buffer time',
    Pleaseenterapositiveintegerfrom0to30000: 'Please enter a positive integer from 500 to 30000',
    Pleaseenterthedevicename: 'Please enter the device name',
    Pleaseselectoperationtype: 'Please select the operating type',
    Pleaseselectthedevicemodel: 'Please select the device name',
    Pleaseselectworkingmode: 'please slect the working mode',
    Pleaseselecttheencodingalgorithm: 'Please select the encoding algorithm',
    Pleaseselecttransportprotocol: 'Please select the transport protocol',
    Pleaseselectoutputpolicy: 'Please select the output strategy',
    Pleaseselectthecoderatemode: 'Please select the bitrate mode',
    Pleaseselectthecodingsystem: 'Please select the encoding system',
    Pleaseselectencodingframerate: 'Please select the encoding framerate',
    Pleaseenterthelatestversionnumber: 'Please enter the latest version number',
    Bundledusers: 'Binding user',
    Unbindingusers: 'Unbinding user',
    Editingdevice: 'Edit deivce',
    Removedevice: 'Remove device',
    Uploadsucceeded: 'Upload complete',
    Areyousureyouwanttoremove: 'Are you sure you want to remove?',
    Thesizeoftheuploadedfile: 'Max upload file size',
    Usermailboxcannotbeempty: 'The email address cannot be empty',
    Deviceserialnumbercannotbeempty: 'The device serial number cannot be empty',
    Workingparameters: 'Parameters',

    Softwareupgrade: 'Software upgrade',
    Inputfilename: 'Enter file name',
    Software: 'Software',
    Generationtime: 'Version number',
    Modelofequipmentused: 'Version timestamp',
    Forceupgrade: 'time stamp',
    versions: 'Minimum version requirements',
    yes: 'Yes',
    no: 'No',
    Selectmodel: 'Please select the moded number',
    Pleaseselectupgradefile: 'Please select the upgrade file',
    Medialist: 'File list',

    Deviceofflinealarm: 'Device off line alarm',
    devicemanagement: 'Device management',
    Equipmentlist: 'Device list',
    Devicelog: 'Device Log',
    Equipmentupgrademanagement: 'Device upgrade management',
    Servermanagement: 'Server management',
    Logmanagement: 'Log management',
    Contractmanagement: 'Contract management',
    Leasecontract: 'Lease contract',
    Salescontract: 'Sales contract',
    Servicecontract: 'Service contract',
    Medialibrary: 'Media library',
    Rolemanagement: 'Role management',
    Rolelist: 'Role list',
    usermanagement: 'User management',
    Cloudadministrator: 'Cloud Administrator',
    Deviceusers: 'Device users',
    Workordermanagement: 'Workorder management',
    Allworkorders: 'All workorders',

    Logtype: 'Log type',
    Operationlog: 'Operation log',
    systemlog: 'System log',
    Latestlogtime: 'The Latest log time',
    Logdetails: 'Log details',
    Operator: 'Operator',
    Operationtime: 'time',
    pedagogicaloperation: 'description',

    Addrole: 'Add role',
    Editorialrole: 'Edit role',
    addsuccess: 'Add success',
    Editorsuccess: 'Edit success',
    deletessuccess: 'Delete success',
    CanceledDelete: 'Canceled Delete',
    Rolename: 'Role name',
    Roledescription: 'Role description',
    Permissionselection: 'Permission selection',
    Pleasefillintherolename: 'Please fill in the role name',
    Pleaseselectpermission: 'Please select permission',
    Tips: 'Tips',
    Thisoperationwillpermanentlydelete: 'This operation will permanently delete the role, Do you want to continue?',

    role: 'Role',
    Addadministrator: 'Addadministrator',
    Editoradministrator: 'Edit administrator',
    Pleaseentertheadministratorname: 'Please enter the administrator name',
    fullname: 'Full name',
    cellphonenumber: 'Mobile',
    Isitonline: 'online',
    VerificationCode: 'Code',
    Pleaseentertheverificationcode: 'Please enter the verification code',
    Sendverificationcode: 'Send',
    Pleaseinputmobilephonenumber: 'Please enter mobilephone number',
    Pleasecompletetheinformation: 'Please complete the information',
    Thisoperationdeletetheadministrator: 'This operation will permanently delete the administrator, Do you want to continue?',
    Thisoperationdeletetheadministrators: 'This operation will permanently delete the file. Do you want to continue?',

    accountnumber: 'Account',
    Numberofonlinedevices: 'online devices',
    Pleaseenterausernameoremailaddress: 'Please enter the username or email address',
    Totalequipment: 'Total equipment',
		istaocan: 'Whether to open the package',
		yunwei: 'maintenance',
		yunweiad: 'Mocha ITOM',
		Totalalls: 'Number of online devices/total',
    Lastlogintime: 'Last login time',
    Disable: 'Disable',
    Enable: 'Enable',

    Assigned: 'Assigned',
    Closed: 'Closed',
    Tobeclosed: 'To be closed',

    Workorderdetails: 'Work order details',
    Problemdescription: 'Problem description',
    reply: 'Reply',
    Answerquestions: 'Answers',
    Pleaseinputthecontent: 'Please enter the content',
    Pleasecompletetheworkorderinformation: 'Please complete the work order information',

    Equipmentparameters: 'Device parameters',
    AfteryouwillunbinditAreyousure: 'Unbind the device after delete it, do you want to continue？',
    Confirmadd: 'Confirmed to add',
    Addaddress: 'Add address',
    Savetocommonstreamingaddress: 'Save as common streaming address',
    Selectaddress: 'Select address',
    Thenameofpushstreamisrepeated: 'The name of streaming is repeated',
    Thestreamingaddressisrepeated: 'The address of streaming is repeated ',


    Areyousureyouwanttodeletethefile: 'Are you sure you want to delete the file',
    Isthatright: ' ',
    Itwillnotberetrievedafterdeletion: 'Irrevocable after delete',
    video: 'Video',
    picture: 'Picture',
    audiofrequency: 'Audio',
    Copysucceeded: 'Copy succeeded',
    copyfailed: 'Copy failed',

    Ordersent: 'Dispatch order',
    Mynickname: 'Nickname',
    Univisocloudliveservice: 'Aftersale service',
    enclosure: 'Attachment',
    Addfile: 'Add attachment',

    Problemsrelatedtolivevideoondemand: 'Problems related to live video or on demand',
    Problemsrelatedtostreamingmediapush: 'Problems related to media streaming',
    Userloginregistration: 'Problems related to login or register',

    Defaultregisteredmobilephone: 'Default registered mobilephone(revisable)',
    Defaultregisteredmobileemail: 'Default registered Email(revisable)',
    Contactemail: 'Contact email',

    headportrait: 'Portrait',
    OnlyJPGandPNGformatsaresupported: 'Only JPG/JPEG and PNG are supported,max size is 2M',
    Uploadnewimage: 'Upload',
    Nickname: 'Nickname',
    Pleaseenteranickname: 'Please enter a nickname',
    Pleaseenterthephonenumbertobebound: 'Please enter the phone number for binding',
    Pleaseinputthecorrectmobilephonenumber: 'Please enter the correct mobilephone number',
    Uploadavatarimagecanonlybe: 'Upload avatar image can only be',
    format: 'Format',
    Thenumberofpicturesuploadedcannotexceed: 'The number of pictures uploaded cannot exceed',

    ChangePassword: 'Change Password',
    Quotabalance: 'Quota balance',
    Streamingscheme: 'Streaming scheme',
    Streamingschemes: 'Push flow',
    setup: 'Setting',
    signout: 'Signout',
    Servicedeadline: 'Service deadline',
    Originalpassword: 'Original',
    Newpassword: 'New',
    Confirmpassword: 'Confirm',
    Logout: 'Logout',
    Doyouwanttologout: 'Do you want to logout?',
    Pleaseentertheoriginalpassword: 'Please enter the original password',
    Pleaseenteranewpassword: 'Please enter the new password',
    Pleaseentertheconfirmationpassword: 'Please enter the confirmed password',
    Pleaseentertheconfirmationpassworda: 'The two passwords are different. Please re-enter',
    Storagebalance: 'Storage balance',
    Used: 'Used',
    Remainingstoragespace: 'Balance space',
    Flowbalance: 'Balance flow',
    Residualflow: 'Balance flow',

    Pleaseenterauniqueserialnumber: 'Please enter a unique serial number',
    Pleaseenteraname: 'Please enter a name',
    Pleaseentertheaddress: 'Please enter a address',
    Selectcommonstreamingaddress: 'Select a common streaming address',
    Nocommonstreamingaddress: 'No common streaming address',
    Gotoadd: 'Goto add',
    Successfullyunbound: 'Unbind succeed',
    Confirmimport: 'Import',
    Noschemetoimport: 'Not available to import ',
    sliceoimport: "Please select option",
    Importsucceeded: 'Import succeeded',
    Range: 'Range',
    Setsuccessfully: 'Set succeed',

    Inputinterface: 'Input interface',
    Inputsource: 'Input source',

    BackHome: 'Back Home',
    AddScheme: 'Add Scheme',
    SchemeName: 'Scheme Name',
    SaveScheme: 'Save Scheme',
    DeleteScheme: 'Delete Scheme',
    FrequentlyStreamAddress: "Frequently Stream Address",
    SaveAddress: 'Save Address',
    Total: "Total",
    Strip: "",
    PasswordNotMatch: "Your new password did not match the two entries",
    tableNull: "no overall data yet",
    ThisActionPermanentlyDeletesTheSchema: 'This action permanently deletes the schema!',
    WhetherOrNotToContinue: 'Whether Or Not To Continue ?',
    ThisActionPermanentlyDeletesTheAddress: 'This action permanently deletes the address',
    OnlyLiveBroadcastingModeCanBeIncluded: 'Only live broadcasting mode can be included',
    total: "gross",
    InputSource: 'Input Source',
    urlsArr: "not have",
    WorkingHours: 'Work Hours',
    TotalStorage: 'Total Storage',
    TotalFLow: 'Total FLow',
    Left: 'Left',
    CannotEdit: 'Cannot edit while offline',
    defaultPassword: 'Password',
    defPassword: 'Please enter a 6- to 16-digit combination of letters and numbers',
    battery: 'No battery',
    again: 'The password has been modified successfully. You are about to log in again',
    Theserial: 'The serial number must be 32 bits',
    free: 'free',
    free_a: 'Push  success',
    free_b: 'Push failed',
    free_c: 'Init failed',
    free_d: 'Init success',
    recordedbroadcast: "The recording begins",
    recordedbroadcasts: 'End of recording',
    livebro: 'Live broadcast stopped',
    livebros: 'Live broadcast begins',
    pulusfuwuq: 'Please add at least one terminal device or server',
    paymentdays: "Date",
    IDs: "ID",
    billnumber: "Bill No.",
    Thebilltype: "Bill Type",
    consumptiontime: "Cycle",
    targetIp: "Target",
    unitprice: "Unit Price",
    unitpriceUnid: "Unit Price Unid",
    dosage: "Flow",
    dosageUnid: "Dosage Unid",
    balance: "Balance",
    paymentamount: "Payment Amount",
    Owetheamount: "Owe The Amount",
    stateofpayment: "Stateof payment",
    dischargepattern: "Discharge pattern",
    oderId: 'OderId',
    ordernumber: 'Order Number',
    ordertype: 'Order Type',
    ordertypes: 'Type',
    bankNumber: 'Account',
    timeofpayment: "Paytime",
    rechargeamount: "Rechar Geamount",
    patternofpayment: "Payment",
    Wechatpayment: 'Wechat payment',
    Alipaypayment: 'Alipay payment',
    orderstatus: "Status",
    odertexts: 'Please enter the order number',
    obligation: 'Obligation',
    lostefficacy: "Lost effectiveness",
    completed: "Completed",
    canceled: "Canceled",
    posoname: "Please enter your user account or bill number",
    centers: "Flow Type",
    la: "Pull flow",
    tui: "Push flow",
    pullzip: "File download",
    PersonalCenter: 'Personal Center',
    Balancerecharge: 'Recharge',
    Balancerecharges: 'Balance recharge',
    balances: 'Balance',
    accountoverview: 'Account overview',
    Ordercenter: 'Order',
    Ordercenters: 'Order',
    billingcenter: 'Billing',
    billingcenters: 'Billing',
    accountsettings: 'Account Settings',
    accountbalance: 'Account Balance',
    returnhome: 'Return to home page',
    Checkaccount: 'Check account number and amount',
    Confirmandpay: 'Confirm and pay',
    complete: 'Complete',
    Rechargeaccount: 'Recharge account',
    Currentbalance: 'Current balance',
    Fastrecharge: 'Fast recharge',
    Otheramounts: 'Other amounts',
    Rechargerecord: 'Recharge record can go to',
    see: 'see',
    Rechargenow: 'Recharge now',
    Rechargenows: 'Recharge',
    Ordersubmitted: 'Order submitted successfully, please pay as soon as possible',
    Pleasecompletepayment: 'Please complete the payment within 60 minutes after submitting the order, otherwise the order will be cancelled automatically',
    Congratulations: 'Congratulations, recharge succeeded!',
    Rechargetime: 'Recharge time',
    Topay: 'To Pay',
    billingcycle: 'Billing cycle',
    Flowbill: 'Flow bill',
    Storebills: 'Store bills',
    Billdetails: 'Bill details',
    Mediafiles: 'Media Files',
    Changetype: 'Change Type',
    Changedosage: 'Change Dosage',
    Totalstorages: 'Total Storage',
    Flowconsumption: 'Flow Type',
    route: 'Path',
    mailboxss: 'Email or mobile',
    Pleaseinputemailss: 'Please enter email or mobile',
    Pleaseinputemailssa: 'Please enter the correct email or mobile number',
    package: 'Please upload the correct package format',
    Uploadsucceededs: 'Upload failed',
    Clickstart: 'Click start live',
    Clickend: 'Click end live',
    Clickstarts: 'Click start recording',
    Clickends: 'Click end recording',
    Signal: 'Offline',
    Signals: 'Signal lost',
    Startrecording: 'Start recording',
    endrecording: 'End collection',
    copy: 'copy',
    Noequipment: 'No equipment',
    Livename: 'Live name',
    Recordingname: 'Recording name',
    myword: 'My Account',
    Youcanpreview: 'You can preview only when live broadcasting is enabled',
    canonlyberecorded: 'It can only be recorded when live broadcasting is enabled',
    Turnonlive: 'Turn on live broadcast',
    Turnonlives: ' Turn off live broadcast',
    Startrecordings: 'Start recordings',
    Startrecordingsa: ' off recordings',
    Operations: 'Operation succeeded',
    userid: 'User account',
    Noversion: 'No version upgrade package available',
    Upgradepackage: 'Upgrade package corrupted',
    Thereisan: 'There is an upgrade package with a timestamp update version on the server',
    Theupgrade: 'The upgrade package model is not recognized',
    Upgrademodule: 'Upgrade module failure',
    Thereareupgrade: 'There are upgrade packages of the same version on the server',
    Illegalrequest: 'Illegal request parameter',
    Failedtoupload: 'Failed to upload file',
    Theupgrades: 'The upgrade package is invalid',
    Submitted: 'Submitted successfully',
    Exitsuccessful: 'Exit successful',
    Pleaseenter: 'Please enter the correct streaming address',
    Sentsuccessfully: 'Sent successfully',
    Thisaccount: 'This account is not bound to the mobile phone. After binding, you can log in directly through the mobile phone number. Do you want to bind it now?',
    Nextlogin: 'Next login is not prompted',
    bindings: 'binding',
    bindingsa: 'Binding succeeded',
    onlythebit: 'Tip: In the working mode, the basic setting can only modify the code rate and buffer time',
    twothebit: 'Prompt: audio and video coding parameters cannot be modified in working mode',
    Programname: 'Program name',
    Programsize: 'Program size',
    coordinate: 'coordinate',
    Selectall: 'Select all',
    Batchdelete: 'Are you sure you want to delete the selected files in batch?',
    Batchdeletes: 'Batch delete',
    Pleaseselectdelete: 'Please select a file to delete',
    streamingAddressaa: 'Address',
    SchemeNamessa: 'Name',
    Pleaseenters: 'Please enter a number of 500-30000',
    Pleaseentersa: 'Please enter a number from 100 to 15360',
    Confirmadds: 'Confirmed',
    addsuccessaa: 'Saved to common streaming address',
    addsuccessbb: 'Successfully added streaming address',
    Accountlogin: 'Account login',
    Codescanninglogin: 'Code scanning login',
    Mobilenumberlogin: 'Mobile login',
    Pleaseloginpassword: 'Please enter the login password',
    Noaccount: 'No account',
    Registernow: 'Register now',
    Signin: 'Sign in',
    Pleaseenternumber: 'Please enter the account number',
    Pleaseenternumbers: 'Please input a password',
    Pleaseenternumbersb: 'Please confirm the password',
    Pleaseenternumbersa: 'Please enter your account and password',
    Thisadministrator: 'This account is disabled, please contact the administrator',
    Pleaseslideverify: 'Please slide the slider to verify',
    Pressandholdright: 'Please drag the slider to the right',
    Verificationpassed: 'Verification passed',
    Returntologin: 'Return to login',
    Bindaccount: 'Bind account',
    Accountregistration: 'Account registration',
    Codescanningregistration: 'Code scanning registration',
    Existingaccount: 'Existing account',
    Loginnow: 'Log in now',
    Wechatregistration: 'Wechat registration',
    loginwassuccessful: 'login was successful',
    Thisaccounthasbeen: 'This account has been registered. Please log in on the login page',
    Codingpreview: 'Coding preview of h.265 is not supported at present',
    Booting: 'Booting',
    Onlyentered: 'Only 0-9, A-Z,a-z,underline and minus sign can be entered',
    Areyousure: 'Are you sure you want to',
    Upcharacters: 'You can enter up to 256 characters',
    Pleaseenterthecorrects: 'Please enter the correct streaming address',
    filesizes: 'file size',
    fileNames: 'file Name',
    close: 'close',
    Arrearagereminder: 'Arrearage reminder',
    balanceenough: 'balance is not enough',
    accountbalances: 'Balance',
    balanceisinsufficient: 'Your current balance is insufficient, please recharge as soon as possible!',
    accountarrears: 'Your current account is in arrears, please recharge as soon as possible!',
    accountarrearsa: 'Your current account is in arrears and shut down. please recharge it as soon as possible',
    Checkamount: 'Check account number and amount',
    Pleaseenterpositive: 'Please enter a positive integer less than or equal to 1000',
    Pleaseenterpositives: 'Please enter a positive integer amount',
    Amountpayable: 'Amount Payable',
    Paywechatcode: 'Pay with wechat scanning code',
    thlisttime: 'Note: the list shows the order records of the latest year. The order records of more than one year can be queried by time',
    query: 'Query',
    Querybymonth: 'Monthly bill',
    Querybyyear: 'Annual bill',
    onlythedetails: 'Note: only the bill details of the last three months can be viewed, and only the general ledger can be viewed for more than three months',
    Billflow: 'Bill flow',
    Pleasebillnumber: 'Please enter the bill number',
    increase: 'Increase',
    reduce: 'Reduce',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    Rechargereminder: 'Recharge reminder',
    Currentaccount: 'Current account balance is',
    Pleaserecharge: 'Please recharge before operation',
    Clickrecharge: 'Click recharge now',
    operationwill: 'This operation will cancel the order. Do you want to continue?',
    Storagepeak: 'Storage peak',
    Storeamount: 'Storage amount',
    Storagepeakmonth: 'Storage peak',
    Currentusage: 'Storage usage',
    Accountsbalance: 'Account Balance',
    Basicsettings: "Basic Settings",
    Audioandvideo: "Audio and video settings",
    Logosettings: "Logo Settings",
    Videoframeratemode: "Video frame rate mode",
    Videoresolution: "Video resolution",
    Videorotationangle: "Video rotation angle",
    VIDEODEinterleaving: "VIDEO DE interleaving",
    Audiocodingmode: "Audio coding mode",
    Audiocodingrate: "Audio coding rate",
    Audirate: "Audio coding sampling rate",
    switch: "ON/OFF",
    imgfenbian: "The picture resolution cannot be greater than 400x300",
    resolvingpower: "Image resolution",
    Picturename: "Picture name",
    width: "Width",
    height: "Height",
    Keephomology: "Keep homology",
    automatic: "automatic",
    Framerate: "Frame rate",
    Rotationangle: "Rotation angle",
    deinterlacing: "deinterlacing",
    open: "open",
    close: "close",
    Audiomode: "Audio mode",
    Audiobitrate: "Audio bit rate",
    Audiosamplingrate: "Audio sampling rate",
    Norotation: "No rotation",
    Rotateone: "Rotate 90°",
    Rotatetwo: "Rotate 180°",
    Rotatether: "Rotate 270°",
    Addpackage: "Add package",
    Packagename: "Package name",
    starttime: "Effective date",
    deadline: "Deadline",
    Months: "Months",
    sort: "sort",
    Costunit: "Cost unit",
    Costunitprice: "Unit Price",
    Discountfee: "Discount fee",
    Flowlimit: "Flow limit",
    storagequota: "Storage quota",
    Excesstrafficstrategy: "Excess traffic strategy",
    Additionalbilling: "Additional billing",
    OutofService: "Out of Service",
    danjiafujia: "Unit price of additional flow",
    cucunxiane: "Storage quota excess policy",
    fujiacucen: "Additional storage unit price",
    Purchasetips: "Purchase tips",
    Serviceinstructions: "Service instructions",
    removeserver: 'Are you sure you want to delete this package?',
    Deletestaocan: 'Delete package',
    Excessstrategy: 'Excess strategy',
    Promptinformation: 'Prompt information',
    accessPort: "Please enter the correct currency unit ('$' or '￥')",
    maxprices: "Price must be greater than 0",
    maxprceaaa: "The maximum price is 9999",
    maxnumbers: "5000 Max",
    xianerices: "Limit must be greater than 0",
    addnames: "Please enter package name",
    datamesg: "Please enter the time",
    alldatamesg: "Please select a time range",
    zekomesgs: "Please enter discount fee",
    feiyongmesg: "Please enter the expense unit",
    danjialsit: "Please enter the unit price",
    liuxianes: "Please enter the traffic limit",
    cunxianes: "Please enter storage quota",
    chaoecel: "Please select excess policy",
    PackageCenter: "Package Center",
    Timeofpurchase: "Time of purchase",
    Buynow: "Buy now",
    Packagemall: "Package mall",
    Mypackage: "My package",
    Totalcost: "Total cost",
    Ihaveread: "I have read",
    already: "I already know",
    woyuedu: "Please read it in detail",
    xiangxiyuedule: "《User service instructions》",
    firstname: "Please read the service instructions first",
    taocanlsit: "Package list",
    xiaofeilieo: "Consumption list",
    shijia: "Actual price of package",
    zongjia: "Total package price",
    addimage: "Please add logo image",
    youhuijiafe: "Preferential price must be less than (unit price * month)",
		shangxiang: 'Upstream traffic',
		xiaxing: 'Downlink traffic',
		neicunad:"Memory(margin)",
		neicunac:"Memory(total)",
		chipanac:"Disk (Remaining)",
		chipanad:"Disk (total)",
		SetRouting:"SetRouting",
		restart:"restart",
		Networkmode:"network",
  }
}