import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
Vue.use(Vuex)

const store = new Vuex.Store({
    state,
    getters,
    mutations,//同步的函数
    actions//异步的函数
})

export default store
