import request from '../utils/request.js'

//获取Token
export function getToken(params) {
	return request({
		url: `/login/token`,
		method: 'get',
		params
	})
}
//登录校验
export function getVerCode(params) {
	return request({
		url: `/login/getVerCode`,
		method: 'get',
		params
	})
}
//获取语言
export function getlanguage(params) {
	return request({
		url: `/media/getLanguage`,
		method: 'get',
		params
	})
}
//用户登录
export function loginAdd(data) {
	return request({
		url: `/login/login`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//手机登录
export function phoneLogin(data) {
	return request({
		url: `/login/loginPhone`,
		method: 'post',
		params: data,
	})
}
//获取手机验证码
export function sendPhoneCode(data) {
	return request({
		url: `/login/sendPhoneCode`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//修改密码
export function Upload(data) {
	return request({
		url: `/user/update/password`,
		method: 'put',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//忘记密码
export function resetPas(data) {
	return request({
		url: `/login/forgetPassword`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//邮箱注册
export function userAdd(data) {
	return request({
		url: `/login/add?email=${data.email}&code=${data.code}&password=${data.password}`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//微信扫码绑定账号
export function bindWxLogin(data) {
	return request({
		url: `/wxlogin/bindWxLogin`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//账号锁定
export function loginLockout(data) {
	return request({
		url: `/login/account/Lockout`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//发送邮箱激活邮件
export function sendMail(data) {
	return request({
		url: `/login/sendMail`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//绑定设备
export function bindEquipment(data) {
	return request({
		url: `/device/add/device`,
		method: 'post',
		data
	})
}
//绑定设备
export function solutionID(data) {
	return request({
		url: `/solution/id`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//删除设备
export function deletes(data) {
	return request({
		url: `/device/admin/delete`,
		method: 'DELETE',
		params: data
	})
}
//获取工单数据
export function byWorkOrder(data) {
	return request({
		url: `/workOrder/byWorkOrder`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//添加方案
export function solution(data) {
	return request({
		url: `/solution/add`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//删除方案或推流
export function deleteSol(data) {
	return request({
		url: `/solution/delete`,
		method: 'delete',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//查询所有方案
export function solList(data) {
	return request({
		url: `/solution/list`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//添加常用推流地址
export function solAdd(data) {
	return request({
		url: `/solution/addPlugFlow`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//方案下添加推流地址
export function solSteam(data) {
	return request({
		url: `/solution/userSteam/solution`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//通过邮箱查用户信息
export function userId(data) {
	return request({
		url: `/user/id`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//修改用户头像昵称手机号
export function userUpdate(data) {
	return request({
		url: `/user/update`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//用户已捆绑设备列表
export function userDevices(data) {
	return request({
		url: `/device/getUserDevices`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//媒体列表
export function mediaList(data) {
	return request({
		url: `/media/list`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//删除媒体列表
export function mediaDelete(data) {
	return request({
		url: `/media/delete`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//编辑媒体列表
export function mediaUpdate(data) {
	return request({
		url: `/media/update`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//获取用户配额
export function deviceQuota(data) {
	return request({
		url: `/device/quota`,
		method: 'get',
		params: data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//账户退出
export function loginOut(data) {
	return request({
		url: "/login/loginOut?loginOutTime=" + data.loginOutTime + "&email=" + data.email,
		method: 'put',
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//设备参数
export function devicePar(data) {
	return request({
		url: `/device/Parameter`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}

/**********************************************/
//获取设备详情
export function getEqDetail(data) {
	return request({
		url: `/device/query/deviceDetails`,
		method: 'get',
		params: data
	})
}

//导入方案
export function importScheme(data) {
	return request({
		url: `/solution/id`,
		method: 'get',
		params: data
	})
}

//添加设备推流地址
export function addEqUrl(data) {
	return request({
		url: `/solution/addDeviceSite`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}

//获取设备列表
export function getEqList(data) {
	return request({
		url: `/device/getUserDevicesVTwo`,
		method: 'get',
		params: data,
	})
}
//获取设备列表
export function loginCode(data) {
	return request({
		url: `/wxlogin/getWxLogin`,
		method: 'get',
		params: data,
	})
}

//获取是否绑定
export function emailApi(data) {
	return request({
		url: `/wxlogin/getUserRegisterStatus`,
		method: 'get',
		params: data,
	})
}

//验证绑定手机与否
export function getBindPhoneStatus(data) {
	return request({
		url: `/login/getBindPhoneStatus`,
		method: 'get',
		params: data,
	})
}

//获取设备的录制的时间
export function getDeviceRecordTime(data) {
	return request({
		url: `/device/getUserDevicesRecordingTime`,
		method: 'get',
		params: data,
	})
}
//直播、录播的开启关闭
export function openzb(data) {
	return request({
		url: `/device/editWorkeDto`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}

//开启关闭网络
export function editDeviceLinkSet(data) {
	return request({
		url: `/device/editDeviceLinkSet`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//设置重启操作
export function editDeviceLinkCtrl(data) {
	return request({
		url: `/device/editDeviceLinkCtrl`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//设置模式操作
export function deviceLinkNetworkModeSet(data) {
	return request({
		url: `/device/deviceLinkNetworkModeSet`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}

