<template>
  <router-view />
</template>

<script>
import * as nav from "@/api/apis";
export default {
  data() {
    return {
      _beforeUnload_time: "",
      gap_time: "",
    };
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },
  methods: {
    beforeunloadHandler(e) {
      this._beforeUnload_time = new Date().getTime();
      //e.returnValue = '关闭提示'; 弹窗   11111
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        let email = JSON.parse(sessionStorage.getItem("user")).email;
        let datas = { email: email };
        nav.loginOut(datas).then((res) => {
          sessionStorage.clear();
        });
      }
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
}
.allNum {
  height: auto !important;
  padding: 0.08rem 0.15rem;
  box-sizing: border-box;
  background: #222628;
  border: 1px solid #464748;
  border-radius: 0.02rem;
  font-size: 0.18rem;
  font-weight: 500;
  color: #b2b2b2;
}
.bottom_tabes {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom_imgs {
  position: relative;
  margin-left: 0.2rem;
  .posis_text {
    position: absolute;
    width: 0.38rem;
    height: 0.22rem;
    font-size: 0.12rem;
    background: #fff;
    z-index: 9999;
    top: -0.1rem;
    display: inline-block;
    left: -0.42rem;
    border-radius: 0.1rem;
  }
}
.bottom_imgs img {
  width: 0.2rem;
  height: 0.2rem;
}
.btn_dise {
  margin-left: 0.2rem;
  color: #4a7da4;
  font-size: 0.16rem;
  display: inline-block;
  padding-bottom: 0.05rem;
}
.searchlist .select_b {
  font-size: 0.16rem !important;
}
.searchlist .searchtime {
  font-size: 0.16rem !important;
}
.el-pagination{
  font-size: 0.16rem !important;
}
.el-pagination span{
  line-height: 0.28rem !important;
}
.bgcard{
  font-size: 0.16rem !important;
}
.el-input__inner {
  color: #606266 !important;
}
.question .describe textarea{
   color: #b2b2b2 !important;
}
.describe .textarea{
  color: #b2b2b2 !important;
}
.basic_a .basic_input .el-select .el-input .el-input__inner{
  color: #b2b2b2 !important;
}
.el-select .el-input .el-input__inner{
  color: #606266 !important;
}
.questionone textarea{
  color: #b2b2b2 !important;
}
.questiontwo input{
  color: #b2b2b2 !important;
}
.questionthree input{
  color: #b2b2b2 !important;
}
.el-message--success {
  top: .8rem !important;
}
 
.el-message .el-icon-success {
  font-size: .16rem;
}

.el-message .el-icon-error {
  font-size: .16rem;
}
.el-message .el-icon-warning {
  font-size: .16rem;
}
.el-message .el-icon-info {
  font-size: .16rem;
}
 
.el-message--success .el-message__content {
  font-size: .16rem;
}

.el-loading-spinner .el-icon-loading{
  font-size: .24rem;
}
.el-dialog{
  overflow: hidden;
}
</style>
