import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import './assets/styles/rest.css';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/styles/less/reviseElementUi.less";
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.prototype.$echarts = echarts;

Vue.use(SlideVerify);

/* 复制内容到剪切板 */
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
/* 语言的切换 */
import Vuei18n from 'vue-i18n'
Vue.use(Vuei18n)
const i18n = new Vuei18n({
  locale: sessionStorage.getItem('language')  || 'cn',
  messages: {
    'cn': Object.assign(require('@/assets/js/lang/cn'),zhLocale),
    'en': Object.assign(require('@/assets/js/lang/en'),enLocale)
  },
	silentTranslationWarn: true
})
//ElementLocale.i18n((key, value) => i18n.t(key, value))
//全局注册
Vue.use(ElementUI,{
	i18n:(key,value)=>i18n.t(key,value)
})

Vue.config.productionTip = false

new Vue({
  i18n, //i18n
  router,
  store,
  render: h => h(App),
}).$mount('#app')
