import axios from 'axios'
import Vue from 'vue'
import { Loading } from 'element-ui'
import { MessageBox } from 'element-ui'
import checkToken from '@/utils/checkToken'
const qs = require('qs')

//const baseURL = 'https://cloud.univiso.tv/api'
// const baseURL = 'https://clouddev.univiso.tv/api'
//const baseURL = 'http://192.168.0.120:8081/api' 
let urls = window.location.hostname
if (window.location.hostname == 'localhost') {
  urls = "clouddev.univiso.tv"
}
const baseURL = 'https://' + urls + '/api'

//export const imgUrl = 'https://cloud.univiso.tv:8082'
// export const imgUrl = 'https://clouddev.univiso.tv:8082'
export const imgUrl = 'https://' + urls + ':8082'

export const upload = baseURL + '/accessory/upload'
export const uploads = baseURL + '/device/uploadDev'
export const uploadtwo = baseURL + '/device/logoImgUpload'
// 创建axios实例
//console.log(baseURL)
//console.log(window.location.hostname)
const service = axios.create({
  baseURL: baseURL,
  //timeout: 3000,
})
let little = '请稍候...'
let Loginstatus = '您的登录状态已失效，请重新登录！'
let Loginstatusa = '权限错误'
let Networkerror = '网络错误，请稍后再试！'
let Networkerrors = '网络错误'
// request拦截器
service.interceptors.request.use(config => {
  // 如果是put/post请求，用qs.stringify序列化参数
  const is_put_post = config.method === 'put' || config.method === 'post'
  const is_json = config.headers['Content-Type'] === 'application/json'
  if (is_put_post && is_json) {
    config.data = JSON.stringify(config.data)
  }
  if (is_put_post && !is_json) {
    config.data = qs.stringify(config.data, { arrayFormat: 'repeat' })
  }
  if (sessionStorage.getItem('language')) {
    if (sessionStorage.getItem('language') == 'zhong') {
      little = '请稍候...'
      Loginstatus = '您的登录状态已失效，请重新登录！'
      Loginstatusa = '权限错误'
      Networkerror = '网络错误，请稍后再试！'
      Networkerrors = '网络错误'
    } else {
      little = 'please wait a moment'
      Loginstatus = 'Your login status has expired. Please login again！'
      Loginstatusa = 'Permission error'
      Networkerror = 'Network error, please try again later!'
      Networkerrors = 'network error'
    }
  }
  // Do something before request is sent
  /** 配置全屏加载 */
  if (config.url != '/user/id' && config.url != '/workOrder/getReadStatus' && config.url.indexOf('/wxPayStatus') == -1 && config.url != '/device/quota' && config.url != '/device/getUserDevicesRecordingTime' && config.url != '/device/getUserDevicesVTwo' && config.url != '/device/query/deviceDetails' && config.url.indexOf('server/serverDetail') == -1) {
    if (config.loading !== false) {
      config.loading = Loading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
        spinner: 'el-icon-loading',
        text: little
      })
    }
  }


  /** 设置令牌 */
  let accessToken = sessionStorage.getItem('token')
  if (accessToken && accessToken != undefined && accessToken != 'undefined') {
    config.headers['Authorization'] = JSON.parse(accessToken)
  }
  return config
}, error => {
  Promise.reject(error)
})
// response拦截器
service.interceptors.response.use(
  async response => {
    await closeLoading(response)
    return response.data
  },
  async error => {
    await closeLoading(error)
    const error_response = error.response || {}
    const error_data = error_response.data || {}
    if (error_response.status === 403) {
      MessageBox.alert(Loginstatus, Loginstatusa, {
        type: 'error',
        callback: () => {
          sessionStorage.clear()
          window.location.replace(`/`)
        }
      })
      return
    }
    if (error.config.msg !== false) {
      // let _message = error.code === 'ECONNABORTED' ? '连接超时，请稍候再试！' : '网络错误，请稍后再试！'
      // Vue.prototype.$message.error(error_data.msg || _message)

      if (error_data.msg) {
        let sk = error_data.msg
        if (sessionStorage.getItem('language') && sessionStorage.getItem('language') == 'zhong') {
          let wq = sk.split('-')
          sk = wq[0]
        } else {
          let wq = sk.split('-')
          sk = wq[1]
        }
        Vue.prototype.$message({
          message: sk,
          type: 'error',
          duration: 1000
        })
        return Promise.reject(error)
      } else {
        MessageBox.alert(Networkerror, Networkerrors, {
          type: 'error',
          callback: () => {
            sessionStorage.clear()
            window.location.replace(`/`)
          }
        })
        Promise.reject(error)
        return
      }
    }
    return Promise.reject(error)
  }
)

/**
 * 关闭全局加载
 * 延迟200毫秒关闭，以免晃眼睛
 * @param target
 */
const closeLoading = (target) => {
  if (!target.config.loading) return true
  return new Promise(resolve => {
    setTimeout(() => {
      target.config.loading.close()
      resolve()
    }, 200)
  })
}


export default function request(options) {
  // 如果是刷新token或者登录，不需要检查token直接请求。
  if (options.url.indexOf('/login/loginOut') + options.url.indexOf('/login/login') + options.url.indexOf('/login/sendMail') > -3) {
    // console.log(options.url + ' | 退出或是登录，不需要检查token直接请求。')
    return service(options)
  }
  return new Promise((resolve) => {
    checkToken(options).then(() => {
      service(options).then(resolve).catch()
    })
  })
}


//export default service